import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

import moodboardMp4 from "../images/moodboard.mp4"
import appStoreBadge from "../images/download-app.svg"
import moodboardPoster from "../images/moodboard-preview.png"

import "./index.scss"

const IndexPage = () => (
  <Layout>
    <SEO title="Moodboard - Photo collage & layout app" />
    <div className="container">
      <div style={{maxWidth: 800}}>
        <p>Contact</p>
        <p>For support requests, questions or suggestions contact me at connor@connorjames.co.</p>
      </div>
    </div>
  </Layout>
)

export default IndexPage
